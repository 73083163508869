const RootstrapLogo = () => (
  <svg
    height="100%"
    width="100%"
    viewBox="0 0 57 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.7798 0L36.3311 15.3309C34.7958 11.2833 31.8891 7.89193 28.1095 5.73868C24.33 3.58544 19.9132 2.80449 15.6168 3.52982C11.3204 4.25515 7.41224 6.44154 4.56278 9.71391C1.71332 12.9863 0.100145 17.1407 0 21.4644H6.56002C6.67903 18.4003 7.96325 15.4946 10.1542 13.3322C12.3451 11.1699 15.2801 9.9114 18.3685 9.81009C21.4568 9.70879 24.4691 10.7722 26.7992 12.7862C29.1292 14.8003 30.604 17.6155 30.9266 20.6651L30.8624 20.7404H30.9441C30.9441 20.9895 30.985 21.4355 30.9908 21.4355H39.2959L56.3788 4.53498L51.7798 0Z"
      fill="currentColor"
    ></path>
  </svg>
);

export default RootstrapLogo;
