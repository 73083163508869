const RootstrapFont = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 148 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00476074 0.563869H5.84107C9.34286 0.563869 11.1288 2.57942 11.1288 5.53324C11.1288 7.89629 10.0315 9.73808 7.76706 10.2825L11.6307 17.4238H8.60165L5.09986 10.7574H2.76534V17.4238H0.00476074V0.563869ZM5.37417 8.60289C7.35268 8.60289 8.37403 7.65883 8.37403 5.67224C8.37403 3.9347 7.49275 2.77634 5.49673 2.77634H2.8237V8.56814L5.37417 8.60289Z"
      fill="currentColor"
    ></path>
    <path
      d="M17.3445 12.3386V5.81703C17.3445 2.19716 19.9416 0.262695 23.3033 0.262695C26.665 0.262695 29.3031 1.87861 29.3031 5.6259V12.1533C29.3031 15.9121 26.6067 17.7076 23.3208 17.7076C20.035 17.7076 17.3386 16.1206 17.3386 12.3386H17.3445ZM26.4666 12.2459V5.57957C26.4666 3.68565 25.206 2.57942 23.2508 2.57942C21.2956 2.57942 20.1517 3.87678 20.1517 5.79387V12.2459C20.1517 14.1572 21.319 15.3677 23.3442 15.3677C23.7641 15.3983 24.1858 15.3384 24.5802 15.192C24.9746 15.0456 25.3324 14.8162 25.6291 14.5197C25.9257 14.2231 26.1542 13.8663 26.2988 13.4738C26.4433 13.0813 26.5006 12.6625 26.4666 12.2459Z"
      fill="currentColor"
    ></path>
    <path
      d="M35.4312 12.3386V5.81703C35.4312 2.19716 38.0284 0.262695 41.3901 0.262695C44.7518 0.262695 47.3956 1.87861 47.3956 5.6259V12.1533C47.3956 15.9121 44.7051 17.7076 41.4192 17.7076C38.1334 17.7076 35.437 16.1206 35.437 12.3386H35.4312ZM44.565 12.2459V5.57957C44.565 3.68565 43.2985 2.57942 41.3492 2.57942C39.3999 2.57942 38.2501 3.87678 38.2501 5.79387V12.2459C38.2501 14.1572 39.4174 15.3677 41.4426 15.3677C41.8625 15.3983 42.2842 15.3384 42.6786 15.192C43.073 15.0456 43.4308 14.8162 43.7275 14.5197C44.0241 14.2231 44.2526 13.8663 44.3972 13.4738C44.5417 13.0813 44.599 12.6625 44.565 12.2459Z"
      fill="currentColor"
    ></path>
    <path
      d="M56.9497 2.84005H52.304V0.563869H64.356V2.84005H59.757V17.4238H56.9497V2.84005Z"
      fill="currentColor"
    ></path>
    <path
      d="M68.6632 15.7442L70.0872 13.7576C71.3763 14.8237 73.0084 15.3951 74.6862 15.3677C76.2854 15.3677 77.476 14.4236 77.476 13.0973C77.476 11.632 76.3554 10.9428 75.0714 10.4273L72.6844 9.47745C70.5424 8.62606 69.4686 7.09123 69.4686 5.03514C69.4686 2.43462 71.4704 0.285864 74.8322 0.285864C76.6089 0.207298 78.3538 0.773485 79.7405 1.87861L78.2873 3.8652C77.0208 2.96747 76.0461 2.49254 74.6862 2.49254C73.1863 2.49254 72.0891 3.20493 72.0891 4.64709C72.0891 5.80545 72.8069 6.46571 74.2602 7.07965L76.4955 8.02371C78.9059 9.04307 80.2599 10.5316 80.2599 12.6108C80.2599 15.7558 77.6161 17.696 74.4236 17.696C72.3148 17.791 70.2482 17.0894 68.6398 15.7326"
      fill="currentColor"
    ></path>
    <path
      d="M89.1078 2.84005H84.4621V0.563869H96.5141V2.84005H91.915V17.4238H89.1078V2.84005Z"
      fill="currentColor"
    ></path>
    <path
      d="M102.152 0.563869H107.988C111.49 0.563869 113.276 2.57942 113.276 5.53324C113.276 7.89629 112.179 9.73808 109.914 10.2825L113.778 17.4238H110.749L107.247 10.7574H104.913V17.4238H102.099L102.152 0.563869ZM107.51 8.60289C109.488 8.60289 110.51 7.65883 110.51 5.67224C110.51 3.9347 109.628 2.77634 107.626 2.77634H104.959V8.56814L107.51 8.60289Z"
      fill="currentColor"
    ></path>
    <path
      d="M123.268 0.517538H126.6L131.608 17.4238H128.795L127.534 12.883H122.1L120.84 17.4006H118.243L123.268 0.517538ZM126.91 10.7574L124.838 3.28602H124.791L122.684 10.7574H126.91Z"
      fill="currentColor"
    ></path>
    <path
      d="M136.989 0.569664H142.872C146.274 0.569664 147.897 2.84005 147.897 5.67224C147.897 8.77085 146.146 11.1107 142.609 11.1107H139.796V17.4238H136.989V0.569664ZM142.061 8.96198C144.039 8.96198 145.154 7.80362 145.154 5.76491C145.154 4.06791 144.203 2.78792 142.393 2.78792H139.796V8.96198H142.061Z"
      fill="currentColor"
    ></path>
  </svg>
);

export default RootstrapFont;
